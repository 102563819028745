import Head from 'next/head';

import { useSelector } from 'react-redux';
import { selectDisableTracking } from '@store/nativeAppConfig/nativeAppConfig.slice';

const HotJarScript = ({ id = null, version = null }) => {
  const disableTracking = useSelector(selectDisableTracking);

  if (disableTracking) {
    return null;
  }

  return (
    <Head>
      <script
        id="hotjar-script"
        dangerouslySetInnerHTML={{
          __html: `
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:${id},hjsv:${version}};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
        }}
      />
    </Head>
  );
};

export default HotJarScript;
